@import "../../scss/_shared.scss";

.main-pet {
  @include form-base_add-width(85%);
  padding: 2vh 1vw 3vh 1vw;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  &__title {
    @include formtitle(2.5vh);
  }

  &__highlighted-text {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  &__card {
    // border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 16em;
    font-family: "Montserrat";
    background-color: #1e1e1e;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 768px) {
      height: 34vh;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      z-index: 1;
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    &-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      z-index: 2;
      opacity: 1;
      color: white;
      padding: 1em;
    }

    &-title {
      margin-top: 0;
      font-size: 1.3rem;
      font-weight: bold;
      text-decoration: none;
      display: block;
      margin-bottom: 0.4em;
      cursor: pointer;

      &:hover {
        background-image: linear-gradient(
          90deg,
          $color--global-js-yellow 0%,
          #989898 90%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-text {
      text-align: center;
      color: #cccccc;
      font-size: 0.9rem;
      line-height: 1.5;
      margin: 1em;
      font-weight: 500;
    }

    &-tech {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1em;
      position: relative;
      top: 10%;

      @media (max-width: 425px) {
        top: 3%;
        gap: 4vw;
      }

      &-img {
        width: 2em;
        height: 2em;

        @media (max-width: 768px) {
          width: 5vw;
        }

        @media (max-width: 425px) {
          width: 7vw;
        }
      }
    }

    &-github {
      position: absolute;
      top: 4%;
      right: 4%;
      z-index: 2;

      &:hover .main-pet__github-img {
        transform: rotate(10deg);
      }
    }
  }

  &__github-img {
    width: 3em;
    height: 2.5em;
    color: white;
    transition: transform 0.2s ease-in-out;
  }
}
